var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-layout", [
    _c("div", { staticClass: "gx-main-content-wrapper gx-custom-login-form" }, [
      _c("div", { staticClass: "gx-login-container" }, [
        _c(
          "div",
          { staticClass: "gx-login-content" },
          [
            _c("div", { staticClass: "gx-login-header gx-text-center" }, [
              _c("h1", { staticClass: "gx-login-title" }, [
                _vm._v("Sign In To SubsApp")
              ])
            ]),
            _c("div", { staticClass: "login-brand" }, [
              _c("img", {
                staticStyle: { "max-height": "80px" },
                attrs: {
                  alt: "SubsApp",
                  src: require("@/assets/regular-logo.png")
                }
              })
            ]),
            _vm.status === "error"
              ? _c("a-alert", {
                  staticClass: "gx-mb-4",
                  attrs: { type: "error", message: _vm.message, banner: "" }
                })
              : _vm._e(),
            _c(
              "a-form",
              {
                staticClass: "gx-login-form gx-form-row0",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.login($event)
                  }
                }
              },
              [
                _c(
                  "a-form-item",
                  [
                    _c(
                      "a-input",
                      {
                        ref: "userNameInput",
                        attrs: { placeholder: "Email" },
                        model: {
                          value: _vm.username,
                          callback: function($$v) {
                            _vm.username = $$v
                          },
                          expression: "username"
                        }
                      },
                      [
                        _c("a-icon", {
                          attrs: { slot: "prefix", type: "user" },
                          slot: "prefix"
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  [
                    _c(
                      "a-input",
                      {
                        ref: "passwordInput",
                        attrs: { placeholder: "Password", type: "password" },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      },
                      [
                        _c("a-icon", {
                          attrs: { slot: "prefix", type: "key" },
                          slot: "prefix"
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  { staticClass: "gx-text-center" },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "gx-mb-0",
                        attrs: {
                          type: "primary",
                          "html-type": "submit",
                          loading: _vm.loginLoading
                        }
                      },
                      [_vm._v("\n              LOGIN\n            ")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "gx-text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "gx-login-form-forgot",
                    attrs: { to: { path: "/auth/forgot-password" } }
                  },
                  [_vm._v("\n            Forgot Password\n          ")]
                )
              ],
              1
            ),
            _c("div", { staticClass: "form-divider" }),
            _c(
              "a-button",
              {
                staticClass: "register-button register-button--member",
                on: {
                  click: function($event) {
                    return _vm.$router.replace({ path: "/register-user" })
                  }
                }
              },
              [_vm._v("\n          Become a Member\n        ")]
            ),
            _c(
              "a-button",
              {
                staticClass: "register-button register-button--club",
                on: {
                  click: function($event) {
                    return _vm.$router.replace({ path: "/register-club" })
                  }
                }
              },
              [_vm._v("\n          Register a Club\n        ")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }